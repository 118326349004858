import { constants } from '@arbitrum/sdk'
import { NativeCurrencyBase } from '../hooks/useNativeCurrency'
import { ChainWithRpcUrl } from './networks'

export type NetworkType =
  | 'Ethereum'
  | 'Rollup'
  | 'AnyTrust'
  | 'Ethereum Testnet'
  | 'Arbitrum Testnet'

export type BridgeUiConfig = {
  color: `#${string}`
  network: {
    name: string
    logo: string
    description?: string
  }
  nativeTokenData?: NativeCurrencyBase
}

type OrbitChainConfig = ChainWithRpcUrl & { bridgeUiConfig: BridgeUiConfig }

export const orbitMainnets: {
  [key: number]: OrbitChainConfig
} = {
  660279: {
    chainID: 660279,
    confirmPeriodBlocks: 45818,
    ethBridge: {
      bridge: '0x7dd8A76bdAeBE3BBBaCD7Aa87f1D4FDa1E60f94f',
      inbox: '0xaE21fDA3de92dE2FDAF606233b2863782Ba046F9',
      outbox: '0x1E400568AD4840dbE50FB32f306B842e9ddeF726',
      rollup: '0xC47DacFbAa80Bd9D8112F4e8069482c2A3221336',
      sequencerInbox: '0x995a9d3ca121D48d21087eDE20bc8acb2398c8B1'
    },
    nativeToken: '0x4Cb9a7AE498CEDcBb5EAe9f25736aE7d428C9D66',
    explorerUrl: 'https://explorer.xai-chain.net',
    rpcUrl: 'https://xai-chain.net/rpc',
    isArbitrum: true,
    isCustom: true,
    name: 'Xai',
    slug: 'xai',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    tokenBridge: {
      l1CustomGateway: '0xb15A0826d65bE4c2fDd961b72636168ee70Af030',
      l1ERC20Gateway: '0xb591cE747CF19cF30e11d656EB94134F523A9e77',
      l1GatewayRouter: '0x22CCA5Dc96a4Ac1EC32c9c7C5ad4D66254a24C35',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0x041f85dd87c46b941dc9b15c6628b19ee5358485',
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0x96551194230725c72ACF8E9573B1382CCBC70635',
      l2ERC20Gateway: '0x0c71417917D24F4A6A6A55559B98c5cCEcb33F7a',
      l2GatewayRouter: '0xd096e8dE90D34de758B0E0bA4a796eA2e1e272cF',
      l2Multicall: '0xEEC168551A85911Ec3A905e0561b656979f3ea67',
      l2ProxyAdmin: '0x56800fDCFbE19Ea3EE9d115dAC30d95d6459c44E',
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 1800000,
    blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
    bridgeUiConfig: {
      color: '#F30019',
      network: {
        name: 'Xai',
        logo: '/images/XaiLogo.svg',
        description:
          'A chain for Web2 and Web3 gamers to play blockchain games.'
      },
      nativeTokenData: {
        name: 'Xai',
        symbol: 'XAI',
        decimals: 18,
        logoUrl: '/images/XaiLogo.svg'
      }
    }
  },
  1380012617: {
    chainID: 1380012617,
    confirmPeriodBlocks: 45818,
    ethBridge: {
      bridge: '0x255f80Ef2F09FCE0944faBb292b8510F01316Cf0',
      inbox: '0x37e60F80d921dc5E7f501a7130F31f6548dBa564',
      outbox: '0x91591BB66075BCfF94AA128B003134165C3Ab83a',
      rollup: '0x2e988Ea0873C9d712628F0bf38DAFdE754927C89',
      sequencerInbox: '0xA436f1867adD490BF1530c636f2FB090758bB6B3'
    },
    explorerUrl: 'https://mainnet.explorer.rarichain.org',
    rpcUrl: 'https://mainnet.rpc.rarichain.org/http',
    isArbitrum: true,
    isCustom: true,
    name: 'RARI Mainnet',
    slug: 'rari-mainnet',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    tokenBridge: {
      l1CustomGateway: '0x8bE956aB42274056ef4471BEb211b33e258b7324',
      l1ERC20Gateway: '0x46406c88285AD9BE2fB23D9aD96Cb578d824cAb6',
      l1GatewayRouter: '0x2623C144B4d167f70893f6A8968B98c89a6C5F97',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0x003e70b041abb993006c03e56c8515622a02928c',
      l1Weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      l1WethGateway: '0x8DF47DAe3313663C80f5E94A893190710A719224',
      l2CustomGateway: '0x90E43f5d772e50B01B3F9596f65AD5653467d010',
      l2ERC20Gateway: '0x0CA4c24079a191e08F659699292e5C75274EF253',
      l2GatewayRouter: '0x9a2859B2a83148b8DE25d26643B5407555D219E1',
      l2Multicall: '0x4c753F58Ee9E83B38170abAbBEa8B47976C7ee1b',
      l2ProxyAdmin: '0x18AB1fE7CBeB5F40d2eAf8A3906A966d59E79767',
      l2Weth: '0xf037540e51D71b2D2B1120e8432bA49F29EDFBD0',
      l2WethGateway: '0xd0C21F7960ea9835E7B2E636548f4deDD9E2309C'
    },
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 1800000,
    blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
    bridgeUiConfig: {
      color: '#B16EFF',
      network: {
        name: 'RARI Mainnet',
        description:
          'A chain designed specifically for NFT royalties and creator empowerment.',
        logo: '/images/RARIMainnetLogo.svg'
      }
    }
  },
  4078: {
    chainID: 4078,
    confirmPeriodBlocks: 7200,
    ethBridge: {
      bridge: '0xB0EC3C1368AF7d9C2CAE6B7f8E022Cc14d59D2b1',
      inbox: '0x18BB8310E3a3DF4EFcCb6B3E9AeCB8bE6d4af07f',
      outbox: '0xD17550876106645988051ffDd31dFc3cDaA29F9c',
      rollup: '0x73CA76d9B04661604fF950fB8DBc9f18F1B853f1',
      sequencerInbox: '0xfb27e42E964F3364630F76D62EB295ae792BD4FA'
    },
    explorerUrl: 'https://muster-explorer.alt.technology',
    rpcUrl: 'https://muster.alt.technology',
    isArbitrum: true,
    isCustom: true,
    name: 'Muster',
    slug: 'muster',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    tokenBridge: {
      l1CustomGateway: '0x6085B32d97be137cC2D6447DcB3BF684C0835D2F',
      l1ERC20Gateway: '0x6551eF99126253B7a838Cf46340030C8eD5342c2',
      l1GatewayRouter: '0x5040981c42fD61219cc567e255129166A840938e',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0x37119EAcFBc1c83DDAf80F6705b6B19630C101C4',
      l1Weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      l1WethGateway: '0x5e833dd255e2aafFcfB32E874F5e2dFA17A109Ee',
      l2CustomGateway: '0x9FcC7aC2c40eFD0443D8B641e482F04310F113f6',
      l2ERC20Gateway: '0xFdEb5b89bb8FCA61BF77f205B9F89aC3C5fA5dB8',
      l2GatewayRouter: '0xDcF4964Dbb526e91CD6354ac3d1247Ce93C21fc4',
      l2Multicall: '0xaA6669a609862871ce72c91a93E70F1ef7590271',
      l2ProxyAdmin: '0xf10D50B24eDd74ECF3B6Bc22aE74b7F9843e0fDD',
      l2Weth: '0x869Bf8814d77106323745758135b999D34C79a87',
      l2WethGateway: '0xB6145BFd3fA9D270871037238003c66B984787f4'
    },
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 1800000,
    blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
    bridgeUiConfig: {
      color: '#DF62DD',
      network: {
        name: 'Muster Network',
        description: 'A gaming chain with cheap fees and account abstraction.',
        logo: '/images/MusterLogo.svg'
      }
    }
  },
  70700: {
    chainID: 70700,
    confirmPeriodBlocks: 40320,
    ethBridge: {
      bridge: '0x074fFD20C6D8865752C997f4980Cf70F2a3Fbac6',
      inbox: '0xC3874bE54E3f25BBC6B4fB582654fd9294f485a1',
      outbox: '0x0cD85675897B7020d7121e63AB250d3F47ff3Ff2',
      rollup: '0x65AD139061B3f6DDb16170a07b925337ddf42407',
      sequencerInbox: '0xa58F38102579dAE7C584850780dDA55744f67DF1'
    },
    explorerUrl: 'https://explorer.apex.proofofplay.com',
    rpcUrl: 'https://rpc.apex.proofofplay.com',
    isArbitrum: true,
    isCustom: true,
    name: 'Proof of Play Apex',
    slug: 'pop-apex',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    tokenBridge: {
      l1CustomGateway: '0x653f8D34a86207569069164d45a031eE552A4729',
      l1ERC20Gateway: '0x298eb8d9f2F046AC60c01535fad40320CCdeB7c0',
      l1GatewayRouter: '0x2f883c5997Cf60B4d52a2fD4039918E1f9D1147c',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0xCC6f49cff395c4d160C61112522700dcB007c41d',
      l1Weth: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
      l1WethGateway: '0xEB2Ae03709f63CEa9E5eC6ab25C1838c4A5634BA',
      l2CustomGateway: '0x1a4ba648Ddc0E726085A847178eBff204411EB1A',
      l2ERC20Gateway: '0x7aEdD5a2F3bBd4841711D017Edf90d611aD96a9e',
      l2GatewayRouter: '0x33e59640CD7E5C5E8D43fd46d995efDdDd0Fc930',
      l2Multicall: '0xEB4150a4F26Cf3563B3a86965E269C8873D48527',
      l2ProxyAdmin: '0x518e5FA773118b779a6231303f5593A10D3B3c84',
      l2Weth: '0x77684A04145a5924eFCE0D92A7c4a2A2E8C359de',
      l2WethGateway: '0x6e965dd667cb08f09DE8285317f012Ac889507b4'
    },
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 1800000,
    blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
    bridgeUiConfig: {
      color: '#3E63DD',
      network: {
        name: 'Proof of Play Apex',
        description:
          "Apex is the first chain in Proof of Play's Multichain, powering the popular Pirate Nation game.",
        logo: '/images/PopApexLogo.svg'
      }
    }
  },
  42001: {
    chainID: 42001,
    confirmPeriodBlocks: 7200,
    ethBridge: {
      bridge: '0x10B25719f4c0fA1BFF22431438E6b6315059548A',
      inbox: '0x1285D6cE3604D341b29ccfF300d043af1CDb57e3',
      outbox: '0x32005e1Ca72cDaAADc2BCFb5E37cc8B2bdb30c60',
      rollup: '0x5c6f7a6CC67F35d8d9A02521E69B80915DA13748',
      sequencerInbox: '0x58b38152Dc53Aab5F6c41f33AA543E224a7FF709'
    },
    nativeToken: '0xBC9B77acA82f6BE43927076D71cd453b625165B8',
    explorerUrl: 'https://explorer.pmon.xyz',
    rpcUrl: 'https://rpc.pmon.xyz',
    isArbitrum: true,
    isCustom: true,
    name: 'PMON Chain',
    slug: 'pmon-chain',
    partnerChainID: 42161,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    tokenBridge: {
      l1CustomGateway: '0x38727FfD8aFAdaeF60687D1E623Fd28B58A2B8a8',
      l1ERC20Gateway: '0x341F7f035f1CBA1E879Df40117f797F88aC703ea',
      l1GatewayRouter: '0xAE4BAD578fff3377FC5Ebfd4d52d3fdd7FAB3017',
      l1MultiCall: '0x90B02D9F861017844F30dFbdF725b6aa84E63822',
      l1ProxyAdmin: '0x50AD12758e5e6320d658B358C731AF6C7FE2b853',
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0xed609532adB4B24cd580d042A05ef15d914Bb7b0',
      l2ERC20Gateway: '0x8624C8046AA1E619528adA4Fa894E431b7CCE139',
      l2GatewayRouter: '0x1d55e424757817CBd27caD7169FE462d6703c57d',
      l2Multicall: '0xB019E8B9448138251a9C58af34FcCd276cE733f6',
      l2ProxyAdmin: '0x8699E41Ed6246708035f7B2E1bf194D9C6Fb7d32',
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 1800000,
    blockTime: constants.ARB_MINIMUM_BLOCK_TIME_IN_SECONDS,
    bridgeUiConfig: {
      color: '#FF3369',
      network: {
        name: 'PMON Chain',
        description:
          'Bridge to PMON Chain for strategic, fully on-chain monster battles and start building your ultimate NFT collection.',
        logo: '/images/PolychainMonstersLogo.png'
      },
      nativeTokenData: {
        name: 'Polkamon',
        symbol: 'PMON',
        decimals: 18,
        logoUrl: '/images/PolychainMonstersLogo.png'
      }
    }
  }
}

export const orbitTestnets: { [key in number]: OrbitChainConfig } = {
  50798: {
    chainID: 50798,
    confirmPeriodBlocks: 60,
    ethBridge: {
      bridge: '0x89Da67bdda8A1dB8671b6ca7362765C07f335620',
      inbox: '0xa0360040cA2132b71b1fBe07D1549e5109d5475F',
      outbox: '0xDAaf0CbB2C4e0c9b73Be693E3886c70AA1aBB2eE',
      rollup: '0xa1fDd179d1833c14F5b5d2DDb1A6BF3BcC73Cdc3',
      sequencerInbox: '0xAD8AAb5F719Ac4b178bAfE17C053d2B2fE973369'
    },
    rpcUrl: 'https://rpc-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz',
    explorerUrl:
      'https://explorerl2new-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz',
    isCustom: true,
    name: 'conduit-orbit-deployer',
    partnerChainID: 11155111,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 900000,
    blockTime: 0.25,
    nativeToken: '0x9c5eb9723728123af896089b902cb17b44fd09e6',
    isArbitrum: true,
    tokenBridge: {
      l1CustomGateway: '0xdAd333022bcD47B7052060974d1ff72CAcAd2D34',
      l1ERC20Gateway: '0x201F866C5Be6eaB9634A0c3FD426BDEc18377b0E',
      l1GatewayRouter: '0x46df9B1588932CeD45072cdb05da8F63B49F265A',
      l1MultiCall: '0x73465577E9FD7Cd585E4270F23A9eBa99B92b6eD',
      l1ProxyAdmin: '0xC23C8eA6Db141dF6e9025913F5DF45C053aD92d9',
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0x6714b16269e3748Fd5929a29eF000b9C479D72ce',
      l2ERC20Gateway: '0x7C54dBdD564f8F72a9B1BD42C0a4493c6e60104b',
      l2GatewayRouter: '0xeda9a3Be51BD4b39E87A9C8D65cC5Cdf9E53F1b3',
      l2Multicall: '0xA4C2d85ccA8d443F9DC19d32Aeb28befC4259FEF',
      l2ProxyAdmin: '0x4a43137F4756B538c33B1d9373FAEeCe74FFfD30',
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    bridgeUiConfig: {
      color: '#009319',
      network: {
        name: 'SXR Testnet Conduit', // 'conduit-orbit-deployer',
        logo: '/images/SxLogo.png',
        description: 'The testnet for SX Rollup (conduit-orbit-deployer).'
      },
      nativeTokenData: {
        name: 'SX Network',
        symbol: 'SX',
        decimals: 18,
        logoUrl: 'https://res.cloudinary.com/sportx/tokens/SX.png'
      }
    }
  },
  79479957: {
    chainID: 79479957,
    confirmPeriodBlocks: 60,
    ethBridge: {
      bridge: '0xC703f27438898BebE682D253185149cBeaC1621c', //
      inbox: '0x46C42Cf5127ffd6dE2d9f231173C6d81E5010726', //
      outbox: '0x5B8220489827Be74CeD6b7974C3a80d3ba8dbf91', //
      rollup: '0x82332BE0ef83A2b7Df91DCDCF481c393455E0D31', //
      sequencerInbox: '0x9555B5F427c7B1E69C35d3DE855D4F1BffBc593B' //
    },
    rpcUrl: 'https://rpc.sx-rollup-testnet.t.raas.gelato.cloud',
    explorerUrl: 'https://explorerl2.toronto.sx.technology',
    isCustom: true,
    name: 'SX Rollup Testnet',
    partnerChainID: 11155111,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 900000,
    blockTime: 0.25,
    nativeToken: '0x9c5eb9723728123af896089b902cb17b44fd09e6',
    isArbitrum: true,
    tokenBridge: {
      l1CustomGateway: '0x2e4C4583a0A228d1A695365b1AE5C32e688ba4B6', //
      l1ERC20Gateway: '0xa66971304463c017F4D152685B1483cc63Fd116d', //
      l1GatewayRouter: '0xEb362E3C9B081baE0024b34955a132B93D05047f', //
      l1MultiCall: '0x73465577E9FD7Cd585E4270F23A9eBa99B92b6eD', //
      l1ProxyAdmin: '0x699cA7B62A560653FE1ac10279C45CA7D641Bf08', //
      l1Weth: '0x0000000000000000000000000000000000000000',
      l1WethGateway: '0x0000000000000000000000000000000000000000',
      l2CustomGateway: '0x8f10E93e92Ed34390E73fBdc0988230a72DC0597', //
      l2ERC20Gateway: '0x650cD99b736173d8AB9aeF9f4b3B5DA8EE054adC', //
      l2GatewayRouter: '0x015B81e0Ead225800cbb11cD95a7048F5E301771', //
      l2Multicall: '0xB1e5913C889727796B3e79712C2A27Be58a166cf', //
      l2ProxyAdmin: '', //
      l2Weth: '0x0000000000000000000000000000000000000000',
      l2WethGateway: '0x0000000000000000000000000000000000000000'
    },
    bridgeUiConfig: {
      color: '#30C349',
      network: {
        name: 'SXR Testnet Gelato',
        logo: '/images/SxLogo.png',
        description: 'The testnet for SX Rollup.'
      },
      nativeTokenData: {
        name: 'SX Network',
        symbol: 'SX',
        decimals: 18,
        logoUrl: 'https://res.cloudinary.com/sportx/tokens/SX.png'
      }
    }
  }
}

export const orbitChains = { ...orbitMainnets, ...orbitTestnets }

export function getOrbitChains(
  {
    mainnet,
    testnet
  }: {
    mainnet: boolean
    testnet: boolean
  } = { mainnet: true, testnet: true }
): OrbitChainConfig[] {
  const mainnetChains = mainnet ? Object.values(orbitMainnets) : []
  const testnetChains = testnet ? Object.values(orbitTestnets) : []

  return [...mainnetChains, ...testnetChains]
}
/*

// New SX Rollup Testnet Gelato
{
  "ChainName": "SX Rollup Testnet",
  "NativeToken": "0x9c5EB9723728123AF896089b902CB17B44Fd09e6",
  "MinL2BaseFee": "100000000",
  "ParentChainId": "11155111",
  "NetworkFeeReceiver": "0x6a14e35149c04232414477FdD93880Ef556a0dC8",
  "InfrastructureFeeCollector": "0x6a14e35149c04232414477FdD93880Ef556a0dC8"
}
{
  "l1": {
    "WETH": "0x0000000000000000000000000000000000000000",
    "Inbox": "0x46C42Cf5127ffd6dE2d9f231173C6d81E5010726", // ---
    "Utils": "0xb33Dca7b17c72CFC311D68C543cd4178E0d7ce55",
    "Bridge": "0xC703f27438898BebE682D253185149cBeaC1621c", // ---
    "Outbox": "0x5B8220489827Be74CeD6b7974C3a80d3ba8dbf91", // ---
    "Rollup": "0x82332BE0ef83A2b7Df91DCDCF481c393455E0D31", // ---
    "Multicall": "0x73465577E9FD7Cd585E4270F23A9eBa99B92b6eD", // ---
    "ProxyAdmin": "0x699cA7B62A560653FE1ac10279C45CA7D641Bf08", // ---
    "WETHGateway": "0x0000000000000000000000000000000000000000",
    "CustomGateway": "0x2e4C4583a0A228d1A695365b1AE5C32e688ba4B6", // ---
    "GatewayRouter": "0xEb362E3C9B081baE0024b34955a132B93D05047f", // ---
    "SequencerInbox": "0x9555B5F427c7B1E69C35d3DE855D4F1BffBc593B", // ---
    "ValidatorUtils": "0xb33Dca7b17c72CFC311D68C543cd4178E0d7ce55",
    "StandardGateway": "0xa66971304463c017F4D152685B1483cc63Fd116d",
    "UpgradeExecutor": "0x25348018796647d5FA39Ce9b152c2E076AEe106B",
    "ChallengeManager": "0x4d134907a04923dDDc4280A6A1162a48afB5AF3e",
    "RollupEventInbox": "0x2FEfCE24081e568f84aB0685191058c9fFB7bD64",
    "ValidatorWalletCreator": "0x75500812ADC9E51b721BEa31Df322EEc66967DDF"
  },
  "l2": {
    "WETH": "0x0000000000000000000000000000000000000000",
    "Multicall": "0xB1e5913C889727796B3e79712C2A27Be58a166cf", // ---
    "WETHGateway": "0x0000000000000000000000000000000000000000",
    "CustomGateway": "0x8f10E93e92Ed34390E73fBdc0988230a72DC0597", // ---
    "GatewayRouter": "0x650cD99b736173d8AB9aeF9f4b3B5DA8EE054adC", // ---
    "StandardGateway": "0x015B81e0Ead225800cbb11cD95a7048F5E301771"
  }
}  

*/
/*
// Add custom orbit chain format
  {
    chainInfo: {
      minL2BaseFee: 10000000,
      networkFeeReceiver: "0x252431e84d5e22435a0c833c2220770c52f59633",
      infrastructureFeeCollector: "0x252431e84d5e22435a0c833c2220770c52f59633",
      batchPoster: "0x34b9076d6Aad94ae2C75e9BEeAA267Ee85caE89f",
      staker: "0x108A34D186C4c823D1f8Bc5F27509Bb5c35fDfAa",
      chainOwner: "0xc7eE12a654b592170C0f79C9bb59296713ceCa62",
      chainName: "conduit-orbit-deployer",
      chainId: 50798,
      parentChainId: 11155111,
      nativeToken: "0x9c5eb9723728123af896089b902cb17b44fd09e6",
      rpcUrl: "https://rpc-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz",
      explorerUrl: "https://explorerl2new-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz"
    },
    coreContracts: {
      rollup: "0xa1fDd179d1833c14F5b5d2DDb1A6BF3BcC73Cdc3",
      inbox: "0xa0360040cA2132b71b1fBe07D1549e5109d5475F",
      outbox: "0xDAaf0CbB2C4e0c9b73Be693E3886c70AA1aBB2eE",
      adminProxy: "0xC23C8eA6Db141dF6e9025913F5DF45C053aD92d9",
      sequencerInbox: "0xAD8AAb5F719Ac4b178bAfE17C053d2B2fE973369",
      bridge: "0x89Da67bdda8A1dB8671b6ca7362765C07f335620",
      utils: "0xb33Dca7b17c72CFC311D68C543cd4178E0d7ce55",
      validatorWalletCreator: "0x75500812ADC9E51b721BEa31Df322EEc66967DDF",
      l3UpgradeExecutor:"0xc292026A2937B9d07B10766F7501f616ceeF1685"
    },
    tokenBridgeContracts: {
      l2Contracts: {
        customGateway: "0xdAd333022bcD47B7052060974d1ff72CAcAd2D34",
        multicall: "0x73465577E9FD7Cd585E4270F23A9eBa99B92b6eD",
        proxyAdmin: "0xC23C8eA6Db141dF6e9025913F5DF45C053aD92d9",
        router: "0x46df9B1588932CeD45072cdb05da8F63B49F265A",
        standardGateway: "0x201F866C5Be6eaB9634A0c3FD426BDEc18377b0E",
        weth: "0x0000000000000000000000000000000000000000",
        wethGateway: "0x0000000000000000000000000000000000000000"
      },
      l3Contracts: {
        customGateway: "0x6714b16269e3748Fd5929a29eF000b9C479D72ce",
        multicall: "0xA4C2d85ccA8d443F9DC19d32Aeb28befC4259FEF",
        proxyAdmin: "0x4a43137F4756B538c33B1d9373FAEeCe74FFfD30",
        router: "0xeda9a3Be51BD4b39E87A9C8D65cC5Cdf9E53F1b3",
        standardGateway: "0x7C54dBdD564f8F72a9B1BD42C0a4493c6e60104b",
        weth: "0x0000000000000000000000000000000000000000",
        wethGateway: "0x0000000000000000000000000000000000000000"
      }
    }
  }

*/

/*
Custom orbit chain local storage
[
  {
    chainID: 50798,
    confirmPeriodBlocks: 60,
    ethBridge: {
      bridge: "0x89Da67bdda8A1dB8671b6ca7362765C07f335620",
      inbox: "0xa0360040cA2132b71b1fBe07D1549e5109d5475F",
      outbox: "0xDAaf0CbB2C4e0c9b73Be693E3886c70AA1aBB2eE",
      rollup: "0xa1fDd179d1833c14F5b5d2DDb1A6BF3BcC73Cdc3",
      sequencerInbox: "0xAD8AAb5F719Ac4b178bAfE17C053d2B2fE973369"
    },
    rpcUrl: "https://rpc-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz",
    explorerUrl: "https://explorerl2new-wispy-apricot-grasshopper-1hi6zpdx62.t.conduit.xyz",
    isCustom: true,
    name: "conduit-orbit-deployer",
    partnerChainID: 11155111,
    partnerChainIDs: [],
    retryableLifetimeSeconds: 604800,
    nitroGenesisBlock: 0,
    nitroGenesisL1Block: 0,
    depositTimeout: 900000,
    blockTime: 0.25,
    nativeToken: "0x9c5eb9723728123af896089b902cb17b44fd09e6",
    isArbitrum: true,
    tokenBridge: {
      l1CustomGateway: "0xdAd333022bcD47B7052060974d1ff72CAcAd2D34",
      l1ERC20Gateway: "0x201F866C5Be6eaB9634A0c3FD426BDEc18377b0E",
      l1GatewayRouter: "0x46df9B1588932CeD45072cdb05da8F63B49F265A",
      l1MultiCall: "0x73465577E9FD7Cd585E4270F23A9eBa99B92b6eD",
      l1ProxyAdmin: "0xC23C8eA6Db141dF6e9025913F5DF45C053aD92d9",
      l1Weth: "0x0000000000000000000000000000000000000000",
      l1WethGateway: "0x0000000000000000000000000000000000000000",
      l2CustomGateway: "0x6714b16269e3748Fd5929a29eF000b9C479D72ce",
      l2ERC20Gateway: "0x7C54dBdD564f8F72a9B1BD42C0a4493c6e60104b",
      l2GatewayRouter: "0xeda9a3Be51BD4b39E87A9C8D65cC5Cdf9E53F1b3",
      l2Multicall: "0xA4C2d85ccA8d443F9DC19d32Aeb28befC4259FEF",
      l2ProxyAdmin: "0x4a43137F4756B538c33B1d9373FAEeCe74FFfD30",
      l2Weth: "0x0000000000000000000000000000000000000000",
      l2WethGateway: "0x0000000000000000000000000000000000000000"
    },
    nativeTokenData: {
      name: "SX Network",
      symbol: "SX",
      decimals: 18,
      address: "0x9c5eb9723728123af896089b902cb17b44fd09e6"
    }
  }
]

*/
